import Axios from 'axios';

/**
 * 초를 HH:MM 포맷으로 변경한다.
 * @param {number} seconds - 변환할 초 단위 시간
 * @returns {string} HH:MM 형식의 시간 문자열
 */
export const toTimeStamp = (seconds) => {
  let sysdate = new Date(seconds);
  let hh = sysdate.getHours();

  hh = hh < 10 ? '0' + hh : hh;
  let mm =
    sysdate.getMinutes() < 10
      ? '0' + sysdate.getMinutes()
      : sysdate.getMinutes();

  if (hh > 12) {
    hh -= 12;
    //hh = (hh < 10) ? ('0' + hh) : hh;
    hh = '오후 ' + hh;
  } else if (hh == 12) {
    hh = '오후 ' + hh;
  } else {
    //hh = (hh < 10) ? ('0' + hh) : hh;
    hh = '오전 ' + hh;
  }

  let result = hh + ':' + mm;

  return result;
};

/**
 * 이전 편성 항목 가져오기
 * @param {Array} scheduleArray - 전체 편성 배열
 * @param {number} index - 현재 항목의 인덱스
 * @returns {Object} 이전 편성 항목 정보
 */
export const getPreviousScheduleItem = (scheduleArray, index) => {
  const simpleObj = {};
  const pre2PlanIndex = parseInt(index - 2);
  const pre1PlanIndex = parseInt(index - 1);

  simpleObj.homepage_url = null;
  simpleObj.starttime =
    scheduleArray[pre2PlanIndex]?.starttime ||
    scheduleArray[pre1PlanIndex]?.starttime ||
    null;
  simpleObj.endtime =
    scheduleArray[pre2PlanIndex]?.endtime ||
    scheduleArray[pre1PlanIndex]?.starttime ||
    null;
  simpleObj.title =
    scheduleArray[pre2PlanIndex]?.title ||
    scheduleArray[pre2PlanIndex]?.title ||
    '정보없음';

  return simpleObj;
};

/**
 * 라이브 편성 정보 불러오기
 * @param {string} channelId - 채널 ID
 * @param {boolean} isMobile - 모바일 여부
 * @returns {Promise<Array>} 편성 정보 배열
 */
export const getLivePlanArray = async (channelId, isMobile) => {
  // 서버 시간을 한국 시간으로 설정
  process.env.TZ = 'Asia/Seoul';

  const channelType = getLiveChannelType(channelId);

  try {
    if (channelType === '24tv') {
      return await get24tvPlan(channelId, isMobile);
    } else {
      return await getTvPlan(channelId, isMobile);
    }
  } catch (error) {
    console.error('getLivePlanArray 오류:', error);
    return [];
  }
};

/**
 * 24시간 TV 편성 정보 가져오기
 * @param {string} channelId - 채널 ID
 * @param {boolean} isMobile - 모바일 여부
 * @returns {Promise<Array>} 처리된 편성 정보 배열
 */
async function get24tvPlan(channelId, isMobile) {
  if (!channelId || channelId === 'undefined') return [];
  const virtualScheduleData = await Axios.get(
    `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE_24TV}/${channelId}`
  );
  const scheduleArray = virtualScheduleData?.data?.list || [];

  scheduleArray.forEach((obj) => {
    obj.starttime_date = `${obj.date.substr(0, 10)}T${obj.date.substr(11)}`;
  });

  return processSchedule(scheduleArray, channelId, isMobile);
}

/**
 * 일반 TV 편성 정보 가져오기
 * @param {string} channelId - 채널 ID
 * @param {boolean} isMobile - 모바일 여부
 * @returns {Promise<Array>} 처리된 편성 정보 배열
 */
async function getTvPlan(channelId, isMobile) {
  const [yesterday, today, tomorrow] = getDates();
  const plans = await Promise.all([
    getPlanForDate(channelId, yesterday),
    getPlanForDate(channelId, today),
    getPlanForDate(channelId, tomorrow),
  ]);

  const array3daysPlan = plans.flat();
  array3daysPlan.sort(
    (a, b) => new Date(a.starttime_date) - new Date(b.starttime_date)
  );
  return processSchedule(array3daysPlan, channelId, isMobile);
}

/**
 * 어제, 오늘, 내일 날짜를 반환
 * @returns {Array<Date>} [어제, 오늘, 내일] 날짜 배열
 */
function getDates() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return [yesterday, today, tomorrow];
}

/**
 * 특정 날짜의 편성 정보 가져오기
 * @param {string} channelId - 채널 ID
 * @param {Date} date - 조회할 날짜
 * @returns {Promise<Array>} 처리된 편성 정보 배열
 */
async function getPlanForDate(channelId, date) {
  if (!channelId) return [];
  const planAPI = toPlanAPIUrl(
    channelId,
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  );
  const response = await Axios.get(planAPI);
  return processPlanData(response.data, date);
}

/**
 * 편성 데이터 처리
 * @param {Array} data - 원본 편성 데이터
 * @param {Date} date - 해당 날짜
 * @returns {Array} 처리된 편성 데이터 배열
 */
function processPlanData(data, date) {
  const koreanDate = new Date(
    date.toLocaleString('en-US', { timeZone: 'Asia/Seoul' })
  );

  return data.map((obj, index, array) => {
    const [hours, minutes] = obj.start_time.split(':').map(Number);

    const startDate = new Date(koreanDate);
    startDate.setHours(hours, minutes);

    // 24시 이후 시간 처리
    if (hours >= 24) {
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(hours % 24);
    }

    obj.starttime_date = startDate.toISOString();
    obj.starttime = `${String(startDate.getHours()).padStart(2, '0')}:${String(
      startDate.getMinutes()
    ).padStart(2, '0')}`;
    obj.endtime = index === array.length - 1 ? '' : array[index + 1].start_time;
    return obj;
  });
}

/**
 * 3일치 데이터 정리
 * @param {Array} scheduleArray - 편성 데이터 배열
 * @param {string} channelId - 채널 ID
 * @param {boolean} isMobile - 모바일 여부
 * @returns {Array} 처리된 편성 데이터 배열
 */
function processSchedule(scheduleArray, channelId, isMobile) {
  const plan = [];
  const korCurrent = getKoreanTime();
  const currentIndex = findCurrentIndex(scheduleArray, korCurrent);

  if (currentIndex !== -1) {
    const startIndex = Math.max(0, currentIndex - 1); //현재 인덱스를 기준으로 이전 1개의 편성을 가져옴(타임머신용)
    const endIndex = Math.min(scheduleArray.length, startIndex + 15);

    for (let i = startIndex; i < endIndex; i++) {
      const simpleObj = createScheduleItem(
        scheduleArray[i],
        scheduleArray[i - 1],
        korCurrent,
        i
      );

      if (shouldAddToPlan(channelId, isMobile, i - startIndex)) {
        plan.push(simpleObj);
      }
    }
  }

  return plan;
}

/**
 * 날짜 문자열을 ISO 문자열로 변환
 * @param {string} dateString - 변환할 날짜 문자열
 * @returns {string} ISO 형식의 날짜 문자열
 */
function toISOString(dateString) {
  const date = new Date(dateString);
  return date.toISOString();
}

/**
 * 서버 시간을 한국 시간으로 변환
 * @returns {Date} 한국 시간으로 변환된 Date 객체
 */
function getKoreanTime() {
  const now = new Date();
  return new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
}

/**
 * 현재 시간에 해당하는 편성 항목의 인덱스 찾기
 * @param {Array} scheduleArray - 전체 편성 배열
 * @param {Date} currentTime - 현재 시간
 * @returns {number} 현재 시간에 해당하는 편성 항목의 인덱스
 */
function findCurrentIndex(scheduleArray, currentTime) {
  return scheduleArray.findIndex((item, index) => {
    const startTime = new Date(item.starttime_date);
    const endTime = scheduleArray[index + 1]
      ? new Date(scheduleArray[index + 1].starttime_date)
      : null;

    return (
      currentTime >= startTime && (endTime === null || currentTime < endTime)
    );
  });
}

/**
 * 편성 항목 객체 생성
 * @param {Object} currentItem - 현재 편성 항목
 * @param {Object} previousItem - 이전 편성 항목
 * @param {Date} currentTime - 현재 시간
 * @returns {Object} 생성된 편성 항목 객체
 */
function createScheduleItem(currentItem, previousItem, currentTime, index) {
  const simpleObj = createJSON(currentItem, previousItem);

  const startTime = new Date(currentItem.starttime_date);
  let endTime = null;
  if (currentItem.endtime) {
    // endTime을 startTime과 같은 날짜를 기준으로 설정
    endTime = new Date(startTime);
    const [endHours, endMinutes] = currentItem.endtime.split(':').map(Number);
    endTime.setHours(endHours, endMinutes);

    // 종료 시간이 시작 시간보다 작으면 다음날로 설정
    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }
  }
  if (currentTime >= startTime && (endTime === null || currentTime < endTime)) {
    simpleObj.status = 'now';
    const koreaTime = new Date(currentTime.getTime());
    simpleObj.current = koreaTime.toLocaleTimeString('ko-KR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  } else if (currentTime < startTime) {
    simpleObj.status = 'upcoming';
  } else {
    simpleObj.status = 'past';
  }

  return simpleObj;
}

/**
 * 편성 항목 JSON 객체 생성
 * @param {Object} currentItem - 현재 편성 항목
 * @param {Object} previousItem - 이전 편성 항목
 * @returns {Object} 생성된 JSON 객체
 */
function createJSON(currentItem, previousItem) {
  return {
    ...currentItem,
    homepage_url:
      currentItem.homepage_url || previousItem?.homepage_url || null,
    thumbnail: currentItem.thumbnail || previousItem?.thumbnail || null,
    starttime: currentItem.starttime || previousItem?.starttime || '',
    endtime: currentItem.endtime || previousItem?.endtime || '',
    title: currentItem.title || previousItem?.title || '정보없음',
  };
}

// 현재 라이브 편성 반영
function shouldSetCurrent(channelId, index, isCurrent, plan) {
  return (
    (getLiveChannelType(channelId) === '24tv' && index === 1 && !isCurrent) ||
    (getLiveChannelType(channelId) !== '24tv' &&
      plan.length === 0 &&
      !isCurrent)
  );
}

function shouldAddToPlan(channelId, isMobile, index) {
  return getLiveChannelType(channelId) === '24tv'
    ? !isMobile || index !== 0
    : true;
}

/**
 * 채널 타입 확인
 * @param {string} channelId - 채널 ID
 * @returns {string} 채널 타입 ('tv', 'radio', '24tv' 중 하나)
 */
export const getLiveChannelType = (channelId) => {
  let type;
  switch (channelId) {
    case 'S01':
    case 'S02':
    case 'S03':
    case 'S04':
    case 'S05':
    case 'S06':
    case 'S09':
    case 'S10':
    case 'S11':
    case 'S12':
    case 'S20':
    case 'EVENT':
    case 'EVENT1':
    case 'EVENT2':
    case 'EVENT3':
    case 'EVENT4':
      type = 'tv';
      break;
    case 'S07':
    case 'S17':
    case 'S08':
    case 'S18':
    case 'S19':
      type = 'radio';
      break;
    default:
      type = '24tv';
      break;
  }
  return type;
};

/**
 * 채널 타입 이름 가져오기
 * @param {string} channelId - 채널 ID
 * @returns {string} 채널 타입 이름
 */
export const getLiveChannelTypeName = (channelId) => {
  let name;
  switch (channelId) {
    case 'S01':
    case 'S02':
    case 'S03':
    case 'S04':
    case 'S05':
    case 'S06':
    case 'S09':
    case 'S10':
    case 'S11':
    case 'S12':
    case 'S20':
    case 'EVENT':
    case 'EVENT1':
    case 'EVENT2':
    case 'EVENT3':
    case 'EVENT4':
      name = '라이브 TV';
      break;
    case 'S07':
    case 'S17':
    case 'S08':
    case 'S18':
    case 'S19':
      name = '라이브 라디오';
      break;
    default:
      name = '24시간 TV';
      break;
  }
  return name;
};

/**
 * 채널이 가상 채널인지 확인
 * @param {string} channelId - 채널 ID
 * @returns {boolean} 가상 채널 여부
 */
export const isVirtualChannel = (channelId) => {
  if (!channelId || typeof channelId !== 'string') return false;
  const firstChar = channelId.charAt(0).toLowerCase();
  if (firstChar !== 's') return false;

  switch (channelId) {
    case 'S01':
    case 'S02':
    case 'S03':
    case 'S04':
    case 'S05':
    case 'S06':
    case 'S07':
    case 'S08':
    case 'S09':
    case 'S10':
    case 'S11':
    case 'S12':
    case 'S17':
    case 'S18':
    case 'S19':
    case 'S20':
      return false;
    default:
      return true;
  }
};

/**
 * 편성표 URL 생성
 * @param {string} channelId - 채널 ID
 * @returns {string} 편성표 URL
 */
export const toPlanUrl = (channelId) => {
  let result;
  switch (channelId) {
    case 'tv':
    case 'S01':
      result = `/schedule/index.html?type=tv&channel=SBS`;
      break;
    case 'S02':
      result = `/schedule/index.html?type=tv&channel=ESPN`;
      break;
    case 'S03':
      result = `/schedule/index.html?type=tv&channel=Plus`;
      break;
    case 'S04':
      result = `/schedule/index.html?type=tv&channel=ETV`;
      break;
    case 'S05':
      result = `/schedule/index.html?type=tv&channel=Golf`;
      break;
    case 'S12':
      result = `/schedule/index.html?type=tv&channel=Golf2`;
      break;
    case 'S06':
      result = `/schedule/index.html?type=tv&channel=CNBC`;
      break;
    case 'S07':
    case 'S17':
      result = `/schedule/index.html?type=ra&channel=Power`;
      break;
    case 'radio':
    case 'S08':
    case 'S18':
      result = `/schedule/index.html?type=ra&channel=Love`;
      break;
    case 'S09':
      result = `/schedule/index.html?type=tv&channel=MTV`;
      break;
    case 'S10':
      result = `/schedule/index.html?type=tv&channel=Nick`;
      break;
    case 'S11':
      result = `/schedule/index.html?type=tv&channel=Fil`;
      break;
    case 'S19':
      result = `/schedule/index.html?type=ra&channel=DMB+Radio`;
      break;
    default:
      result = `/schedule/index.html?div=pc_scheduler`;
      break;
  }
  return result;
};

/**
 * 채널별 편성표 API URL 생성
 * @param {string} channelId - 채널 ID
 * @param {number} yyyy - 연도
 * @param {number} mm - 월
 * @param {number} dd - 일
 * @returns {string} 편성표 API URL
 */
export const toPlanAPIUrl = (channelId, yyyy, mm, dd) => {
  let result;
  switch (channelId) {
    case 'S01':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/SBS.json`;
      break;
    case 'S02':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/ESPN.json`;
      break;
    case 'S03':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Plus.json`;
      break;
    case 'S04':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/ETV.json`;
      break;
    case 'S05':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Golf.json`;
      break;
    case 'S09':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/MTV.json`;
      break;
    case 'S12':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Golf2.json`;
      break;
    case 'S06':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/CNBC.json`;
      break;
    case 'S07':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Power.json`;
      break;
    case 'S17':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Power.json`;
      break;
    case 'S08':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Love.json`;
      break;
    case 'S18':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Love.json`;
      break;
    case 'S09':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/MTV.json`;
      break;
    case 'S10':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Nick.json`;
      break;
    case 'S11':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/Fil.json`;
      break;
    case 'S19':
      result = `${process.env.NEXT_PUBLIC_API_LIVE_SCHEDULE}/${yyyy}/${mm}/${dd}/DMB+Radio.json`;
      break;
    default:
      result = null;
      break;
  }
  return result;
};

/**
 * 메시지 텍스트 파싱
 * @param {string} text - 파싱할 텍스트
 * @returns {string} 파싱된 메시지
 */
export const getMsg = (text) => {
  try {
    if (typeof text === 'string' && text.includes('{')) {
      const parsedText = text.replace(/^"|"$/g, '');
      const jsonObject = JSON.parse(parsedText);
      return jsonObject.msg || text;
    }
    return text;
  } catch (e) {
    console.error('메시지 파싱 중 오류 발생:', e);
    return text;
  }
};

/**
 * 이미지 URL 파싱
 * @param {string} text - 파싱할 텍스트
 * @returns {string|null} 파싱된 이미지 URL 또는 null
 */
export const getImg = (text) => {
  if (typeof text !== 'string' || !text.includes('{')) {
    return null;
  }

  try {
    const cleanedText = text.replace(/^"|"$/g, '');
    const parsedData = JSON.parse(cleanedText);

    if (parsedData.emoticon_pc && parsedData.emoticon_pc.length > 0) {
      return decodeURIComponent(parsedData.emoticon_pc);
    } else if (parsedData.emoticon && parsedData.emoticon.length > 0) {
      // 잘못된 데이터이지만 노출함
      return decodeURIComponent(parsedData.emoticon);
    }
  } catch (e) {
    console.error('이미지 URL 파싱 중 오류 발생:', e);
  }

  return null;
};

/**
 * URL에 div 파라미터 추가
 * @param {string} link - 원본 URL
 * @returns {string} div 파라미터가 추가된 URL
 */
export const addDivParam = (link) => {
  const url = new URL(link, window.location.origin);
  if (!url.searchParams.has('div')) {
    url.searchParams.append('div', 'live_list');
  }
  return url.toString();
};

/**
 * 방송의 현재 진행률 계산
 * @param {string} startTime - 방송 시작 시간 (형식: "HH:MM")
 * @param {string} endTime - 방송 종료 시간 (형식: "HH:MM")
 * @returns {number} 현재 진행률 (0-100 사이의 정수)
 */
export const calculateProgress = (startTime, endTime) => {
  const now = new Date();
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  const start = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    startHour,
    startMinute
  );
  const end = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    endHour,
    endMinute
  );

  // 종료 시간이 다음 날인 경우 (예: 23:00 ~ 01:00)
  if (end < start) {
    end.setDate(end.getDate() + 1);
  }

  if (now < start || now > end) return 0;

  const totalDuration = end - start;
  const elapsedTime = now - start;

  return Math.round((elapsedTime / totalDuration) * 100);
};
